.icon {
  width: 24px;
  height: 24px;
  float: left;
  background-size: contain;
  margin-right: 3px;
  pointer-events: none;
  &.first {
    background-image: url("./images/trophy-1.png");
  }
  &.second {
    background-image: url("./images/trophy-2.png");
  }
  &.third {
    background-image: url("./images/trophy-3.png");
  }
  &.best {
    background-image: url("./images/crowns.png");
  }
  &.love {
    background-image: url("./images/heart.png");
  }
  &.chiptune {
    background-image: url("./images/robot.png");
  }
  &.small {
    width: 16px;
    height: 16px;
  }
  &.big {
    width: 48px;
    height: 48px;
  }
}
