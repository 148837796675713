*,
html,
body {
  font-family: Arial;
  // font-size: 1em;
  margin: 0;
  padding: 0;
  font-family: "vp-pixel";
  font-size: 20px;
}

body {
  background: #333;
  margin: 5px;
  overflow-x: hidden;
  /*overflow-y: auto;*/
}
button {
  cursor: pointer;
}
p {
  color: #666;
  font-size: 0.8em;
}
#title-track {
  color: white;
}
h1 {
  color: #fff;
  text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
    0 0 30px rgba(202, 228, 225, 0.42), 0 0 12px rgba(30, 132, 242, 0.58),
    0 0 22px rgba(30, 132, 242, 0.84), 0 0 38px rgba(30, 132, 242, 0.88),
    0 0 60px rgba(30, 132, 242, 1);
  font-size: 45px;
}
#block {
  margin-left: 5px;
}

h2 {
  color: #fff;
  margin-top: 10px;
  font-size: 32px;
  text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
    0 0 30px rgba(202, 228, 225, 0.42), 0 0 12px rgba(242, 132, 30, 0.58),
    0 0 22px rgba(242, 132, 30, 0.84), 0 0 38px rgba(242, 132, 30, 0.88),
    0 0 60px rgba(242, 132, 30, 1);
}

#primary-block {
  z-index: 1000;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  #title-block {
    margin-left: 15px;
    margin-bottom: 10px;
  }
}

#timer {
  position: fixed;
  bottom: 15px;
  right: 15px;
  color: white;
  opacity: 0.3;
}

#toolbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: #333;
}

.floatL {
  float: left;
}

.floatR {
  float: right;
}

.clearfix {
  clear: both;
  height: 1px;
}

.center {
  text-align: center;
}

#control {
  margin-right: 10px;
}

.btn {
  margin-top: 10px;
  padding: 5px;
  font-size: 0.8em;
  border: 1px #333 solid;
  background: #555;
  color: #999;
  border-radius: 5px;
  &:active {
    background: #222;
  }
  &:hover {
    background: #666;
  }
  &.selected {
    background: #959;
  }
}

#loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #444;
  z-index: 1001;
}

#progress {
  background: #f00;
  box-shadow: 1px 1px 10px red;
  width: 0;
  display: block;
  height: 4px;
  transition: opacity 0.3s ease-out;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#tracks {
  width: 900px;
  top: 75px;
  position: relative;
  margin-bottom: 50px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  background: linear-gradient(
    180deg,
    rgba(255, 0, 0, 0.3) 0%,
    rgba(0, 255, 0, 0.3) 25%,
    rgba(0, 0, 255, 0.3) 50%,
    rgba(255, 0, 255, 0.3) 75%,
    rgba(255, 192, 92, 0.3) 100%
  );
  border: 4px solid;
  border-image: linear-gradient(
      0deg,
      rgba(255, 0, 0, 0.3) 0%,
      rgba(0, 255, 0, 0.3) 25%,
      rgba(0, 0, 255, 0.3) 50%,
      rgba(255, 0, 255, 0.3) 75%,
      rgba(255, 192, 92, 0.3) 100%
    )
    1 100%;
  border-top: none;
  border-bottom: none;
}

#instruments {
  color: #777;
  position: fixed;
  right: 15px;
  top: 65px;
  padding: 5px;
  font-size: 0.8em;
  max-height: 300px;
  width: 250px;
  border: 1px #444 solid;
  overflow: auto;
  overflow-x: hidden;
  background: #222;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 200% 400%;
  }
}

canvas {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgb(1, 4, 5);
  background: linear-gradient(
    0deg,
    rgb(8, 7, 25) 15%,
    rgba(0, 0, 0, 1) 50%,
    rgba(50, 16, 103, 1) 85%,
    rgba(0, 0, 0, 1) 100%
  );
}

ul {
  list-style-type: none;
  padding-left: 15px;
  padding-top: 0;
  margin-top: 0;

  li {
    &.active {
      border-bottom: 2px dashed white;
      background-image: url("./images/progress.png");
      background-repeat: repeat-y;
      background-size: 50% auto;
    }
    button {
      color: #ddd;
      background: none;
      border: none;
      display: block;
      background: none;
      text-decoration: none;
      padding: 5px;
      opacity: 0.5;
      cursor: pointer;
      width: 100%;
      background: rgba(255, 77, 106, 0);
      transition: background-color 0.5s ease-out;
      text-align: left;

      &:hover {
        color: white;
        background: rgba(255, 77, 106, 1);
        transition: background-color 0s ease-out;
        z-index: 100;
        opacity: 1;
      }

      &.active {
        color: #fff;
        display: block;
        background: none;
        font-weight: bold;
        opacity: 1;
        z-index: 100;
        text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
          0 0 30px rgba(202, 228, 225, 0.42), 0 0 12px rgba(30, 132, 242, 0.58),
          0 0 22px rgba(242, 30, 242, 0.84), 0 0 38px rgba(30, 132, 242, 0.88),
          0 0 60px rgba(242, 30, 242, 1);
      }
    }
  }
}

.shadowed {
  -webkit-filter: drop-shadow(-1px 1px 1px #00000030);
  filter: drop-shadow(-1px 1px 1px #00000030);
}

#filters {
  float: left;
  margin-left: 40px;
  margin-top: 10px;
  input {
    border: 1px solid #999;
    background: #acacac;
    color: white;
    padding: 5px;
    &:focus {
      outline: none;
    }
  }
}
